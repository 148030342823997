import { RouterStateSnapshot } from '@angular/router';
import { RouterStateSerializer } from '@ngxs/router-plugin';

import { StoreRouterStateModel } from '../models';

// Map the router snapshot to { url, params, queryParams, data }
export class CustomRouterStateSerializer
  implements RouterStateSerializer<StoreRouterStateModel>
{
  serialize(routerState: RouterStateSnapshot): StoreRouterStateModel {
    const {
      url,
      root: { queryParams },
    } = routerState;

    let { root: route } = routerState;

    while (route.firstChild) {
      route = route.firstChild;
    }

    const { params, data } = route;

    if (route.params['title']) {
      data['title'] = route.params['title'];
    }

    return { url, params, queryParams, data };
  }
}
