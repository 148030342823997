import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { map, Observable, take } from 'rxjs';

import { RouteSelectors } from '../selectors';

@Injectable({ providedIn: 'root' })
export class RouteStoreService {
  constructor(private store: Store) {}

  getPathParamByKey(paramKey: string): Observable<string> {
    return this.store
      .select((state) => RouteSelectors.getPathParamByKey(state.router))
      .pipe(
        take(1),
        map((selectorFn) => selectorFn(paramKey)),
      );
  }

  getQueryParamByKey(queryParamKey: string): Observable<string> {
    return this.store
      .select((state) => RouteSelectors.getQueryParamByKey(state.router))
      .pipe(
        take(1),
        map((selectorFn) => selectorFn(queryParamKey)),
      );
  }
}
